export const deDE = {
  SEO: {
    title: "DoryGo - Wir kümmern uns um Ihre Medikamente",
    description: "Ihr rundum sorglos Paket für Medikamente. Wir verwalten Ihre Rezepte, liefern Ihnen Ihre Medikamente vorsortiert nach Hause und erinnern Sie an die Einnahme.",
  },
  home: {
    Header: {
      Slogan1: "Wir kümmern uns um Ihre Medikamente",
      Slogan2: "<ul> <li>Ihre Medikamente sortiert und geliefert </li> <li>Übersichtliche Medikamentenapp </li> <li>Handliche Medikamentenbox </li> </ul>",
      Recommendation: "Empfohlen von:",
      RecommendationDZ: "Ihr kompetenter Ansprechpartner rund um Diabetes im Kanton Zürich",
      RecommendationEpi: "Die Patientenorganisation für Menschen mit Epilepsien und Angehörige",
      ButtonCTAText: "Jetzt prüfen, ob DoryGo für Sie verfügbar ist",
      ButtonTxt: "Jetzt testen",
    },
    Overview: {
      Title1: "Liefer&shy;service",
      B1: "Ihre Medi&shy;kamente werden von er&shy;fahr&shy;enen Apo&shy;thek&shy;ern in einem Wochen&shy;blister vor&shy;sor&shy;tiert und regel­mä&shy;ig direkt zu Ihnen nach Hause ge&shy;lief&shy;ert.",
      B2: "Mit un&shy;ser&shy;er kom&shy;pak&shy;ten DoryGo Box haben Sie Ihren Wo&shy;chen&shy;be&shy;darf an Medi&shy;ka&shy;men&shy;ten dank 7x3 Fä&shy;chern im&shy;mer dabei. </br>Sie ist de&shy;zent, liegt be&shy;quem in der Hand und Ihre Medi&shy;ka&shy;men&shy;te blei&shy;ben ge&shy;schützt.",
      B3: "Die DoryGo App erinnert Sie daran, Ihre Medi&shy;kamente ein&shy;zu&shy;nehmen. Alle Erin&shy;ner&shy;un&shy;gen sind durch die Apo&shy;theke bereits für Sie ein&shy;ge&shy;rich&shy;tet. <br/>Än&shy;der&shy;ung&shy;en tei&shy;len Sie der Apo&shy;theke ein&shy;fach in der App mit.",
      S1: {
        B1: "Vorsortierte Medikamente",
        B2: "Regelmäßige  Lieferung nach Hause",
        B3: "Von deutscher Apotheke",
      },
      Title2: "Medi&shy;kamenten&shy;box",
      S2: {
        B1: "7x3 Zeitpunkte",
        B2: "Klein & Handlich",
        B3: "Schützt Ihre Medikamente",
      },
      Title3: "App",
      S3: {
        B1: "Erinnerungen",
        B2: "Eingerichtet durch die Apotheke",
        B3: "Einfach Rezepte anfordern",
      },
      LearnMore: "Mehr erfahren",
    },
    HowTo: {
      Header: "So einfach funktioniert unser Service",
      S1: "1. Melden Sie sich an",
      B1:
        "Mel&shy;den Sie sich ein&shy;fach on&shy;line an. Da&shy;nach kön&shy;nen Sie Ih&shy;re Re&shy;zep&shy;te di&shy;rekt in der App ein&shy;reichen.",
      S2: "2. Wir sortieren Ihre Medikamente",
      B2:
        "Die Apo&shy;the&shy;ke sor&shy;tiert Ihre Medi&shy;ka&shy;men&shy;te nach Ein&shy;nah&shy;me&shy;zeit&shy;punkt vor, si&shy;ch&shy;er ver&shy;packt für die gan&shy;ze Woche.",
      S3: "3. Wir liefern zu Ihnen nach Hause",
      B3: "Sie er&shy;hal&shy;ten Ihre Medi&shy;ka&shy;men&shy;te re&shy;gel&shy;mäs&shy;sig ge&shy;lie&shy;fert - im&shy;mer recht&shy;zeit&shy;ig.",
      ButtonCTAText: "Jetzt prüfen, ob DoryGo für Sie verfügbar ist",
    },
    Video: {
      Header: "DoryGo kurz erklärt",
    },
    Incentive: {
      Header: "30 Tage kostenlos testen",
      SubHeader:
        "Testen Sie DoryGo kostenlos und unverbindlich für 30 Tage.<br/><br/>Anschließend nur <b>4,90 Euro </b>pro Woche",
      ButtonTxt: "Jetzt testen",
    },
    CallToAction: {
      Header: "30 Tage kosten&shy;los testen",
      SubHeader:
        "Testen Sie den DoryGo Ser&shy;vice jetzt kosten&shy;los und un&shy;ver&shy;bindlich für 30 Tage.<br/><br/>Anschließend nur<b> 4,90 Euro pro Woche</b>",
      ButtonTxt: "Jetzt unverbindlich testen",
    },
    MainBenefits: {
      Header: "Ihre Vorteile",
      SubHeader: "Wir kümmern uns um ...",
      F1: {
        Title: "Ihre Rezepte",
        Body:
          "Wir küm&shy;mern uns darum, dass Ihr Arzt alle nötigen Re&shy;zep&shy;te bei der Apo&shy;theke einreicht",
      },
      F2: {
        Title: "Ihre Medi&shy;kamente",
        Body:
          "Unsere deutsche Partner&shy;apotheke sortiert Ihre Medi&shy;kamente nach Ein&shy;nahme&shy;zeit&shy;punkt vor und liefert Sie Ihnen direkt nach Hause.",
      },
      F3: {
        Title: "Ihren Medi&shy;kamenten&shy;plan",
        Body:
          "Die Apo&shy;theke erstellt für Sie den Medi&shy;kamenten&shy;plan in der DoryGo App und richtet die Erin&shy;nerungen für Sie ein. Änder&shy;ungen werden automatisch in die App übertragen.",
      },
    },
    BoxExplained: {
      Header: "Wie funktioniert die Box?",
      Body: "Mit nur ei&shy;nem Hand&shy;griff haben Sie eine gan&shy;ze Wo&shy;chen&shy;pack&shy;ung mit vor&shy;sor&shy;tier&shy;ten Medi&shy;ka&shy;men&shy;ten in die Box gelegt. So sind die Medi&shy;ka&shy;men&shy;te op&shy;ti&shy;mal ge&shy;schützt und las&shy;sen sich leicht heraus&shy;drü&shy;cken.",
    },
    Quotes: {
      Quote1: {
        Body: "Toller Service. Die regel&shy;mäßigen Liefer&shy;ungen und die App machen das Leben deut&shy;lich einfacher.",
        Author: "Stephanie, 58"
      },
      Quote2: {
        Body: "Der Service ist echt genial! Er ist eine große Erleichterung. Ich habe ihn schon vielen inklusive meinem Hausarzt empfohlen. ",
        Author: "Sandra, 38",
      },
      Quote3: {
        Body: "Ich sehe im DoryGo System ein grosses Poten&shy;zial, den Behand­&shy;lungs&shy;­erfolg zu ver­bes&shy;­sern und gleich­&shy;zeitig das Leben meiner Patienten zu erleichtern.",
        Author: "Dr. Alexander Meisel, Onkologe, Universitäts&shy;spital Zürich"
      },

    },
    FAQ: [
      {
        Title:
          "Kann ich auch Medikamente für mehr als 2 Wochen auf einmal erhalten?",
        Body:
          "In fast allen Fällen können die Medikamente auch für einen längeren Zeitraum geliefert werden. Ihre Apotheke hilft Ihnen bei dieser Frage gerne weiter. Die Kontaktdaten finden Sie in der DoryGo App unter 'Ihre Apotheke'.",
      },
      {
        Title: "Welche Apotheke liefert meine Medikamente?",
        Body:
          "Ihre Medikamente werden von der Lünsche Apotheke in Lüdenscheid geliefert.",
      },
      {
        Title: "Was passiert, wenn ein Medikament nicht lieferbar ist?",
        Body:
          "Bei Lieferengpässen stellt die Apotheke nach Rücksprache mit Ihnen und Ihrem Arzt auf ein Generikum um.",
      },
      {
        Title: "Was mache ich, wenn sich meine Medikamente ändern?",
        Body: "Sie können Änderungen in wenigen Klicks der Apotheke mitteilen.",
      },
      {
        Title: "Was kostet die Box?",
        Body: "Die Box ist bei unserem Service inklusive.",
      },
      {
        Title: "Gibt es die Box noch in anderen Farben und Grössen?",
        Body: "Die Box ist zur Zeit nur in einer Farbe und Grösse erhältlich.",
      },
      {
        Title:
          "Ich habe noch Medikamente zu Hause. Kann ich den Service auch später starten?",
        Body: "Sie können das Startdatum frei wählen.",
      },
      {
        Title: "Kann ich auch Spritzen/Salben/etc über den Service beziehen?",
        Body:
          "Ihre Apotheke liefert Ihnen Medikamente, welche nicht in die Box passen, gerne separat mit. Sie werden auch hier über die App an die Einnahme und ablaufende Rezepte erinnert.",
      },
      {
        Title: "An wen kann ich mich bei Fragen wenden?",
        Body:
          "Sie können uns gerne unter +41 44 551 43 74 oder hello@dorygo.ch kontaktieren. Bei Fragen zu Ihren Medikamenten hilft Ihnen Ihre Apotheke gerne weiter. Sie finden die Kontaktdaten Ihrer Apotheke in der DoryGo App unter 'Ihre Apotheke'.",
      },
      {
        Title: "Wie kann ich meine Rezepte einreichen?",
        Body:
          "Sie können Ihre Rezepte als Photo über die App/E-Mail oder per Post einreichen. Oder Sie stellen uns einfach eine Rezeptevollmacht aus. Dann kümmern wir uns darum, dass Ihr Arzt alle Rezepte rechtzeitig bei der Apotheke einreicht.",
      },
      {
        Title: "Wie funktioniert die Rezeptevollmacht?",
        Body: "Sie haben die Möglichkeit, uns online eine Rezeptevollmacht auszustellen. Dann kümmern wir uns darum, dass Ihr Arzt alle Rezepte unserer Partnerapotheke einreicht. ",
      },
      {
        Title: "Liefern Sie die Medikamente auch ins Ausland?",
        Body:
          "Die Medikamente können nur innerhalb der Schweiz geliefert werden. In den meisten Fällen können die Medikamente jedoch für einen längeren Zeitraum im Voraus geliefert werden.",
      },
      {
        Title: "Wie werden die Medikamente geliefert?",
        Body:
          "Die Medikamente werden durch die Post alle zwei Wochen geliefert. Die Lieferung ist für Sie kostenlos.",
      },
      {
        Title: "Was ist alles im Service enthalten?",
        Body:
          "Der DoryGo Service beinhaltet die Medikamentenbox, die App und den Lieferservice inklusive sämtlichen Lieferkosten.",
      },
    ],
    PharmacyPartner: {
      SectionHeader: "Erfahrene Apotheker",
      SectionBody: 'Sie erhalten Ihre Medikamente von erfahrenen Apotheken in Deutschland wie zum Bespiel der <a href="https://www.luensche-apotheke.de/" target="_blank"rel="noreferrer noopener">Lünsche Apotheke</a> in Lüdenscheid. Durch deren langjährige Erfahrung in der Verblisterung von Medikamenten werden Sie optimal betreut.',
    },
    TestOrContact: {
      Partners: "Unsere Partner",
    },
  },
  footer: [
    {
      sectionName: 'Allgemein',
      links: [
        {
          label: 'Home',
          url: '/de-de/',
        },
        {
          label: 'So funktioniert es',
          url: '/de-de/vorteile',
        },
        {
          label: 'Preise',
          url: '/de-de/preise',
        },
      ],
    },
    {
      sectionName: 'Über uns',
      links: [
        {
          label: 'Über uns',
          url: '/de-de/about',
        },
        {
          label: 'Kontakt',
          url: '/de-de/about',
        },
      ],
    },
    {
      sectionName: 'Gesundheitspersonal',
      links: [
        {
          label: 'Apotheken',
          url: '/de-de/apotheken',
        },
      ],
    },
    {
      sectionName: 'Folgen Sie uns auf',
      links: [
        {
          label: 'Facebook',
          url: 'https://www.facebook.com/DoryHealth-100218411671110',
          rel: 'external',
        },
        {
          label: 'LinkedIn',
          url: 'https://www.linkedin.com/company/doryhealth/',
          rel: 'external',
        },
      ],
    },
    {
      sectionName: 'Rechtliches',
      links: [
        {
          label: 'Impressum',
          url: '/impressum',
        },
        {
          label: 'Datenschutzvereinbarung',
          url: '/datenschutzvereinbarung',
        },
        {
          label: 'Nutzungsbedingungen',
          url: '/nutzungsbedingungen',
        },
      ],
    },
  ],
  features: {
    Box: {
      Slogan: "Ihre Medikamente immer dabei",
      Category1: "Gewicht",
      Value1: "116g",
      Category2: "Grösse",
      Value2: "15x11x3cm",
      Category3: "#Zeitpunkte",
      Value3: "7x3",
      Category4: "Pillen pro Zeitpunkt",
      Value4: "bis zu 7",

      F1: {
        Title: "Einfache Ent&shy;nahme",
        Body:
          "Durch das weiche Ver&shy;packungs&shy;material und das optimierte Design lassen sich die Medi&shy;kamente mit wenig Kraft herausdrücken.",
      },
      F2: {
        Title: "Schnell nachgefüllt",
        Body:
          "Die Box ist in Sekunden&shy;schnelle nachgefüllt. Leere Packung raus - volle Packung rein.",
      },
      F3: {
        Title: "Schützt Ihre Medi&shy;kamente",
        Body:
          "Durch die Kom&shy;bi&shy;nation der harten Box und der weichen Verpackung sind Ihre Medikamente optimal geschützt.",
      },
    },
    App: {
      header: "Ihre Rezepte immer im Griff",
      F1: {
        Title: "Medi&shy;ka&shy;men&shy;ten&shy;er&shy;in&shy;ner&shy;ung&shy;en",
        Body:
          "Wir erin&shy;nern Sie zu&shy;ver&shy;läs&shy;sig an die Ein&shy;nahme Ih&shy;rer Medi&shy;kamente.",
      },

      F2: {
        Title: "Eingerichtet durch Apotheke",
        Body:
          "Ihr Medi&shy;ka&shy;men&shy;ten&shy;plan und die Erin&shy;ner&shy;ungen werden durch die Apo&shy;theke für Sie ein&shy;ge&shy;richt&shy;et und verwaltet.",
      },
      F3: {
        Title: "Ihre Daten sind sicher",
        Body:
          "Wir legen gros&shy;sen Wert auf Ihre Pri&shy;vat&shy;sphä&shy;re. Darum wer&shy;den Ihre Da&shy;ten gemäss den deu&shy;tschen und Schwei&shy;zer Daten&shy;schutz&shy;gesetzen ver&shy;schlüs&shy;selt in Eu&shy;ropa gespeichert.",
      },
      F4: {
        Title: "Rezepterinnerungen",
        Body:
          "Wir erinnern Sie rechtzeitig daran, dass Ihr Rezept bald abläuft.",
      },
      F5: {
        Title: "Rezepte einfach anfordern",
        Body:
          "Ihr Rezept können Sie in wenigen Klicks anfordern. Falls gewünscht, über&shy;nehmen wir das auch für Sie. ",
      },
    },
    DeliveryService: {
      Header: "Lieferung direkt nach Hause",
      Title1: "Vorsortiert",
      S1: {
        B1: "Von deutscher Apotheke",
        B2: "Einfache Entnahme",
        B3: "In praktischen Nachfüll&shy;packungen",
      },
      Title2: "Regelmäßige Lieferung",
      S2: {
        B1: "Lieferung per Post ",
        B2: "Alle 2-3 Wochen",
        B3: "Urlaubsservice",
      },
      Title3: "Persönlich betreut",
      S3: {
        B1: "Unsere Partner&shy;apotheken helfen bei Fragen gerne weiter",
        B2: "Per Telefon, E-Mail oder Chat",
      },
      Slogan2: "Diese Apotheken beliefern Sie",
      Slogan3: "Mehr Zeit für die schönen Dinge des Lebens",
    },
    CallToAction: {
      Header: "30 Tage kosten&shy;los testen",
      SubHeader:
        "Testen Sie den DoryGo Ser&shy;vice jetzt kosten&shy;los und un&shy;ver&shy;bindlich für 30 Tage. <br/><br/>Anschließend nur 4,90 Euro pro Woche",
      ButtonTxt: "Jetzt unverbindlich testen",
      Unsure: "Noch nicht ganz sicher?",
      AskConsult: "Beratungsgespräch anfordern",
    },
    FAQ: [
      {
        Title:
          "Kann ich auch Medikamente für mehr als 2 Wochen auf einmal erhalten?",
        Body:
          "In fast allen Fällen können die Medikamente auch für einen längeren Zeitraum geliefert werden. Ihre Apotheke hilft Ihnen bei dieser Frage gerne weiter. Die Kontaktdaten finden Sie in der DoryGo App unter 'Ihre Apotheke'.",
      },
      {
        Title: "Welche Apotheke liefert meine Medikamente?",
        Body:
          "Ihre Medikamente werden von der Central Apotheke Olten geliefert.",
      },
      {
        Title: "Was passiert, wenn ein Medikament nicht lieferbar ist?",
        Body:
          "Bei Lieferengpässen stellt die Apotheke nach Rücksprache mit Ihnen und Ihrem Arzt auf ein Generikum um.",
      },
      {
        Title: "Was mache ich, wenn sich meine Medikamente ändern?",
        Body: "Sie können Änderungen in wenigen Klicks der Apotheke mitteilen.",
      },
      {
        Title: "Was kostet die Box?",
        Body: "Die Box ist bei unserem Service inklusive.",
      },
      {
        Title: "Gibt es die Box noch in anderen Farben und Grössen?",
        Body: "Die Box ist zur Zeit nur in einer Farbe und Grösse erhältlich.",
      },

      {
        Title: "Kann ich auch Spritzen/Salben/etc über den Service beziehen?",
        Body:
          "Ihre Apotheke liefert Ihnen Medikamente, welche nicht in die Box passen, gerne separat mit. Sie werden auch hier über die App an die Einnahme und ablaufende Rezepte erinnert.",
      },
      {
        Title: "An wen kann ich mich bei Fragen wenden?",
        Body:
          "Sie können uns gerne unter +41 44 551 43 74 oder hello@dorygo.ch kontaktieren. Bei Fragen zu Ihren Medikamenten hilft Ihnen Ihre Apotheke gerne weiter. Sie finden die Kontaktdaten Ihrer Apotheke in der DoryGo App unter 'Ihre Apotheke'.",
      },
      {
        Title: "Wie kann ich meine Rezepte einreichen?",
        Body:
          "Sie können Ihre Rezepte als Photo über die App/E-Mail oder per Post einreichen. Oder Sie stellen uns einfach eine Rezeptevollmacht aus. Dann kümmern wir uns darum, dass Ihr Arzt alle Rezepte rechtzeitig bei der Apotheke einreicht.",
      },
      {
        Title: "Wie funktioniert die Rezeptevollmacht?",
        Body: "Nachdem Sie den Service abgeschlossen haben, haben Sie die Möglichkeit, uns online eine Rezeptevollmacht auszustellen. Diese erlaubt uns, Ihren Arzt aufzufordern, die Rezepte direkt bei der Apotheke einzusenden. Damit kümmern wir uns darum, dass Ihre Rezepte rechtzeitig bei der Apotheke eintreffen und auch wieder nachbestellt werden.",
      },
      {
        Title: "Liefern Sie die Medikamente auch ins Ausland?",
        Body:
          "Die Medikamente können nur innerhalb der Schweiz geliefert werden. In den meisten Fällen können die Medikamente jedoch für einen längeren Zeitraum im Voraus geliefert werden.",
      },
      {
        Title: "Wie werden die Medikamente geliefert?",
        Body:
          "Die Medikamente werden durch die Post alle zwei Wochen geliefert. Die Lieferung ist für Sie kostenlos.",
      },
      {
        Title: "Was ist alles im Service enthalten?",
        Body:
          "Der DoryGo Service beinhaltet die Medikamentenbox, die App und den Lieferservice inklusive sämtlichen Lieferkosten.",
      },
    ],
  },
  prices: {
    Benefit1: "Testen Sie DoryGo für 30 Tage kostenlos",
    Benefit2: "Testen Sie den Service ohne Risiko. Der kostenpflichtige Service startet nicht automatisch.",
    Alert1: "Nur bis zum 8. September",
    SmallTxt1: "DoryGo Service",
    Price: "4,90 € pro Woche.",
    BtnTxt: "Jetzt unverbindlich testen",
    Promise1: "Jederzeit kündbar",
    Promise2: "Keine versteckten Kosten",
    ButtonTxt: "Jetzt unverbindlich testen",
    Unsure: "Noch nicht ganz sicher?",
    AskConsult: "Beratungsgespräch anfordern",

    Onboarding: {
      Header: " So melden Sie sich an",
      Title1: "Konto anlegen",
      Body1: "Unverbindlich Konto anlegen.",
      Title2: "Service einrichten",
      Body2:
        "Hier legen Sie Ihr Startdatum fest und beantworten einige kurze Fragen für die Apotheke.",
      Title3: "Rezepte einsenden",
      Body3:
        "Wir bieten Ihnen die Möglichkeit, uns eine Vollmacht zu erteilen. Damit können wir veranlassen, dass Ihr Arzt die nötigen Rezepte direkt bei der Apotheke einreicht. <br /> <br /> Natürlich können Sie die Rezepte auch selbst einreichen.",
      Title4: "Loslegen",
      Body4:
        "Sie erhalten Ihre sortierten Medikamente und die DoryGo Box pünktlich zum gewählten Startdatum.",
    },
    FAQ: [
      {
        Title: "Verlängert sich der Service automatisch?",
        Body:
          "Nach der Probezeit verlängert sich der Service nicht automatisch. Sobald Sie nach der Probezeit ein kostenpflichtiges Abo abgeschlossen haben, verlängert sich der Service jeweils automatisch um einen Monat. ",
      },
      {
        Title: "Wie kann ich den Service kündigen?",
        Body:
          "Sie können den Service jederzeit per Ende Monat mit einem E-Mail an hello@doryhealth.com kündigen. ",
      },
      {
        Title: "Wie viel kostet der Service?",
        Body:
          "Nach der Probezeit kostet der Service 4,90 Euro pro Woche",
      },
      {
        Title: "Werden die Kosten von DoryGo durch die Krankenkasse übernommen?",
        Body:
          "Zurzeit werden die Kosten nicht durch die Krankenkasse übernommen.",
      },
      {
        Title: "Können die Kosten für DoryGo an die Zuzahlung angerechnet werden?",
        Body:
          "Nein, die Kosten für DoryGo können leider nicht an die Zuzahlung angerechnet werden.",
      },

      {
        Title: "Was ist alles im Service enthalten?",
        Body:
          "Der DoryGo Service beinhaltet die Medikamentenbox, die App und den Lieferservice inklusive sämtlichen Lieferkosten.",
      },
      {
        Title: "Wieviel kostet der Versand der Medikamente?",
        Body: "Die Lieferung der Medikamente ist für Sie kostenlos.",
      },

      {
        Title: "An wen kann ich mich bei Fragen wenden?",
        Body:
          "Sie können uns gerne unter +41 44 551 43 74 oder hello@dorygo.ch kontaktieren. Bei Fragen zu Ihren Medikamenten hilft Ihnen Ihre Apotheke gerne weiter. Sie finden die Kontaktdaten Ihrer Apotheke in der DoryGo App unter 'Ihre Apotheke'.",
      },
      {
        Title:
          "Ich habe noch Medikamente zu Hause. Kann ich den Service auch später starten?",
        Body: "Sie können das Startdatum frei wählen.",
      },
    ],
  },
  Pharmacy: {
    Slogan: "Unser Angebot für Apotheken",
    Contact: {
      Header: "Haben wir Ihr Interesse geweckt?",
      SubText:
        "Kontaktieren Sie uns über das Kontaktformular und wir werden uns umgehend bei Ihnen melden.",
      Pharmacy: "Apotheke*",
      Name: "Name*",
      Email: "E-Mail*",
      Phone: "Telefonnummer*",
      Message: "Nachricht*",
      Mandatory: "*Pflichtfeld",
      BtnTxt: "Ich habe Interesse",
    },
    Benefits: {
      SectionHeader: "Was wir bieten",
      SectionSubHeader:
        "Eine Partner&shy;schaft mit uns bietet Ihnen viele Vor&shy;teile:",
      Benefit1: {
        Header: "Neu&shy;kunden&shy;gewinnung",
        Body:
          "Sie erhalten eine neue Mög&shy;lich&shy;keit Kunde von sich zu über&shy;zeugen. <br /> <br />",
      },
      Benefit2: {
        Header: "Ver&shy;besserte Kunden&shy;bindung",
        Body:
          "Mit unserem Service ver&shy;bessern Sie die Erfahrung Ihrer Kunden und erhöhen die Kun&shy;den&shy;bindung.",
      },
      Benefit3: {
        Header: "Apo&shy;theken&shy;portal",
        Body:
          "Wir stellen Ihnen kosten&shy;frei ein intuitives Apo&shy;theken&shy;portal für die Verwaltung Ihrer Dory&shy;Go Kunden zur Ver&shy;fügung.",
      },
      Benefit4: {
        Header: "",
        Body: "",
      },

      ReqHeader: "Was wir suchen",
      ReqSubHeader:
        "Wir suchen Apotheken, die folgende Voraus&shy;setzungen erfüllen:",
      Req1: {
        Header: "Versand&shy;handels&shy;lizenz",
        Body:
          "Ein Kern&shy;bestand&shy;teil des DoryGo Services ist die Lieferung der Medi&shy;kamente. Daher benötigen teil&shy;nehmende Apo&shy;theken ent&shy;weder eine Versand&shy;handels&shy;bewil&shy;ligung oder müs&shy;sen bereit sein in ihrer Region einen Liefer&shy;dienst anzubieten.",
      },
      Req2: {
        Header: "Blister&shy;service",
        Body:
          "Wir setzen vorraus, dass Sie die notwendige Infra&shy;struktur und Lizenzen besitzen um einen Blister&shy;auftrag ent&shy;gegen&shy;zu&shy;nehmen und zeit&shy;gerecht aus&shy;zu&shy;führen.",
      },
    },
  },
  about: {
    CompanyDetails: {
      Header: "Über uns",
      Body:
        "Die DoryHealth AG ist ein Unternehmen aus Zürich und wurde 2020 von zwei ETH Biochemikern gegründet. Wir haben uns zum Ziel gesetzt, das Leben von Menschen mit chronischen Krankheiten so einfach wie möglich zu gestalten. Wir arbeiten mit erfahrenen Apotheken und Ärzten aus der Schweiz und Deutschland, um unseren Kunden eine optimale und bequeme Versorgung zu ermöglichen.",
    },
    Team: {
      Header: "Das Team",
      SG: {
        Name: "Stephan Gath",
        Role: "Gründer - CEO",
        Body:
          "Stephan ist verantwortlich für die Geschäftsentwicklung und Finanzen. ",
      },
      LI: {
        Name: "Luca Isenmann",
        Role: "Gründer - COO",
        Body:
          "Luca ist verantwortlich für die Produktentwicklung und Produktion. ",
      },
      JC: {
        Name: "Jerome Castan",
        Role: "Gründer - COO",
        Body:
          "Jerome Castan hat einen Abschluss in Physik der ETH Zürich und ist für die Produktentwicklung und Produktion zuständig.",
      },
      BS: {
        Name: "Barbara Staehelin",
        Role: "Berater",
        Body:
          "Barbara Staehelin unterstützt das Gründerteam in der strategischen Entwicklung durch ihre jahrelange Erfahrung in der Gesundheitsindustrie.",
      },
      HV: {
        Name: "Dr. Heiko Visarius",
        Role: "Berater",
        Body:
          "Heiko Visarius besitzt eine langjährige Erfahrung in der Medizingeräteindustrie und unterstützt das DoryHealth Team im Rahmen des Innosuisse Coachings.",
      },
    },
  },
  ContactMe: {
    name: "ContactMe",
    subject: "ContactMe Website",
    action: "",
    successMessage: "Vielen Dank für Ihr Interesse. Wir werden uns so schnell wie möglich bei Ihnen melden.",
    errorMessage: "Ein Fehler ist aufgetreten. Versuchen Sie es erneut oder senden Sie uns eine E-Mail.",
    WeHelp: "Wir helfen Ihnen gerne weiter",
    WeContactFast: "Bitte geben Sie Ihre Kontaktdaten ein. Wir werden Sie so schnell wie möglich kontaktieren. ",
    BtnSend: "Absenden",
    placeholder: "Möchten Sie uns noch etwas mitteilen?",
  },
  Onboarding: {
    Intro: {
      Title: "Willkommen bei DoryGo!",
      Body:
        "Sie können sich jetzt in der App mit Ihrer E-Mail-Adresse und Ihrem gewählten Passwort anmelden.  <br/><br/>  Damit Sie Ihre erste Lieferung erhalten, benötigt Ihre Apotheke noch einige Angaben. <br/><br/>Den Service zu konfigurieren dauert nur ein paar Minuten. ",
      ButtonForwardTxt: "Service konfigurieren",
      ButtonCntLater: "Später in App fortfahren",
    },
    Medication: {
      medicationTitle: "Startdatum wählen",
      txtMedicationDelivery:
        "Wann benötigen Sie die erste Medikamentenlieferung?",
      labelStartDate: "Startdatum",
      medicationExplanation:
        "Sie erhalten die erste Lieferung eine Woche vor dem angegebenen Datum.",
      btnNext: "Weiter",
      btnBack: "Zurück",
      errorStartDate: "Bitte wählen Sie ein gültiges Datum.",
    },
    Address: {
      addressTitle: "Lieferadresse",
      labelAddress: "Strasse und Hausnummer",
      labelZipCode: "Postleitzahl",
      labelCity: "Stadt",
      labelCountry: "Land",
      optionSwitzerland: "Schweiz",
      optionGermany: "Deutschland",
      labelPhone: "Telefonnummer",
      btnNext: "Weiter",
      btnBack: "Zurück",
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
      errorZipCodeInvalid: "Postleitzahl ungültig",
      errorInputInvalid: "Eingabe ungültig",
    },
    Pharmacy: {
      txtExplanation:
        "Die Apotheke benötigt diese Informationen um Ihre Bestellung ausführen zu können.",
      labelGender: "Geschlecht",
      optionMale: "Männlich",
      optionFemale: "Weiblich",
      txtPregnant: "Sind Sie schwanger oder am stillen?",
      labelPregnant: "Schwanger/Stillend?",
      optionPregnant: "Ja, schwanger",
      optionBreastfeeding: "Ja, am stillen",
      optionYes: "Ja",
      optionNo: "Nein",
      txtDiseases: "An welchen Krankheiten leiden Sie?",
      labelDiseases: "Krankheiten",
      txtAllergies: "Haben Sie Allergien?",
      labelAllergies: "Allergie",
      txtWhichAllergies: "Welche?",
      labelWhichAllergies: "Welche Allergien?",
      txtOtherMedications:
        "Nehmen Sie noch weitere Medikamente, pflanzliche Mittel, Vitamine oder Ähnliches ein?",
      labelOtherMedications: "Andere Medikamente",
      labelWhichOtherMedications: "Was?",
      btnNext: "Weiter",
      btnBack: "Zurück",
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
    },
    InsuranceCard: {
      insuranceCardTitle: "",
      txtInsuranceExplanation: "",
      labelInsuranceName: "",
      errorInvalidCardNumber: "",
      labelCardNumber: "",
      btnNext: "",
      btnBack: "",
    },
    AuthorizationLetter: {
      Title: "Rezepte einreichen",
      Body1:
        "Die Apotheke benötigt von Ihnen die Rezepte aller Medikamente <br /> <br />",
      SubTitle1: "Überlassen Sie das uns",
      Body2:
        "Wir bieten Ihnen die Möglichkeit, uns eine Vollmacht zu erteilen. Damit können wir veranlassen, dass Ihr Arzt die nötigen Rezepte direkt bei der Apotheke einreicht. <br /> <br /> Natürlich können Sie die Rezepte auch selbst einreichen.",
      btnSelf: "Rezepte selbst einreichen",
      btnAuthForm: "Vollmacht ausfüllen",
      btnBack: "Zurück",
      PhysicianTitle: "Ärzte angeben",
      PhysicianBody1:
        " Wir benötigen die Namen aller Ärzte die Ihnen momentan Medikamente verschrieben haben.",
      PhysicianName: "Arzt",
      labelPhysicianName: "Vorname Nachname",
      labelPhysicianLocation: "Ort",
      btnAddPhysicians: "Arzt hinzufügen",
      btnRemovePhysicians: "Arzt entfernen",
      btnOpenAuthForm: "Zur Vollmacht",
    },
  },
  Success: {
    AuthFormSigned: {
      Title: "Gratulation",
      SubTitle: "Sie haben den Service fertig eingerichtet.",
      Body1:
        "In den nächsten Tagen erhalten Sie Ihr Willkommenspaket mit Ihrer persönlichen DoryGo Box. <br /><br />Als Nächstes küm&shy;mern wir uns darum, dass Ihr Arzt alle Re&shy;ze&shy;pte bei der Apotheke einreicht. Sobald die Rezepte eingetroffen sind, werden wir Sie benachrichtigen.  ",
      btnTxt: "Zusammenfassung öffnen",
      Body2:
        "Wir haben alle wichtigen Informationen für Sie und Ihren Arzt kurz zusammengefasst.",
      Body3: "Herzliche Grüße <br /> Ihr DoryGo Team",
    },
    AuthFormNotSigned: {
      Title: "Gratulation",
      SubTitle: "Sie haben den Service fertig eingerichtet.",
      Body1:
        "In den nächsten Tagen erhalten Sie Ihr Willkommenspaket mit Ihrer persönlichen DoryGo Box.",
      btnTxt: "Zusammenfassung öffnen",
      SubTitle2: "Rezepte einreichen",
      Body2:
        "Ihre Apotheke benötigt von Ihnen noch Ihre Medikamentenrezepte. <br /> Sie können die Rezepte einfach per Post bei der von Ihnen gewählte Apotheke einreichen. <br />  Die Versandadresse erhalten Sie in wenigen Minuten per E-Mail.",
      Body3:
        "Wir haben alle wichtigen Informationen für Sie und Ihren Arzt kurz zusammengefasst.",
      Body4: "Herzliche Grüße <br /> Ihr DoryGo Team",
    },
  },
  Checkout: {
    Login: {
      login: "Login",
      labelEmail: "E-Mail",
      labelPassword: "Passwort",
      btnLoginTxt: "Anmelden",
      btnCreateAccountTxt: "Konto anlegen",
      errorEmail: "E-Mail nicht korrekt",
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
      errorWrongPassword: "Passwort oder E-Mail ungültig",
      errorTooManyRequests:
        "Der Zugriff auf dieses Konto wurde aufgrund zu vielen fehlgeschlagenen Anmeldeversuche vorübergehend deaktiviert. Bitte versuchen Sie es später erneut oder setzten Sie Ihr Passwort zurück.",
      errorUnknown: "Es ist ein unerwarteter Fehler aufgetreten",
      forgottenPassword: "Ich habe mein Passwort vergessen"
    },
    CreateAccount: {
      txtWelcome: "Es fehlen nur noch wenige Schritte zu Ihrem DoryGo Service.",
      subTitle1: "Konto erstellen",
      labelEmail: 'E-Mail',
      labelPassword: "Passwort",
      labelPasswordRepeat: "Passwort wiederholen",
      subTitle2: "Allgemeine Angaben",
      labelFirstName: 'Vorname',
      labelLastName: 'Nachname',
      labelTitle: "Anrede",
      optionMale: "Herr",
      optionFemale: "Frau",
      labelCountry: "Land",
      labelPreferredLanguage: "Bevorzugte Sprache",
      optionSwitzerland: 'Schweiz',
      optionGermany: 'Deutschland',
      optionGerman: "Deutsch",
      optionFrench: "Französisch",
      optionEnglish: "Englisch",
      labelBirthdate: 'Geburtsdatum',
      labelAcceptData1: 'Ich stimme der Verarbeitung der personenbezogenen Daten gemäss der ',
      labelAcceptData2: 'Datenschutzerklärung ',
      labelAcceptData3: 'zu.',
      labelAcceptTerms1: 'Ich stimme den ',
      labelAcceptTerms2: 'Nutzungsbedingungen ',
      labelAcceptTerms3: 'zu.',
      labelAcceptNews: "Ich möchte über Neuigkeiten und Angebote informiert werden",
      btnLoginTxt: "Ich habe bereits ein Konto",
      btnCreateAccountTxt: "Konto anlegen",
      errorEmailExists: "Diese Mailadresse wird bereits verwendet",
      errorUnknown: "Es ist ein unerwarteter Fehler aufgetreten",
      errorValidDate: "Bitte wählen Sie ein gültiges Datum.",
      errorPermissionRequired: 'Zustimmung benötigt um fortzufahren',
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
      errorEmail: "E-Mail ungültig",
      errorPasswordInvalid: "Das Passwort muss mindestens 8 Zeichen lang sein, sowie mindestens einen Grossbuchstaben, Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
      errorPasswordMissmatch: "Passwörter stimmen nicht überein",
    },
    Pharmacy: {
      Body1: "Ihre Apotheke",
      Body2: "Bei Fragen hilft Ihnen Ihre Apotheke gerne weiter.",
      labelPharmacySwitch: "Ich möchte durch die Lünsche Apotheke beliefert werden.",
      btnNextTxt: "Weiter",
      errorAcceptedPharmacy: 'Zustimmung benötigt um fortzufahren',
    },
    FinishOrder: {
      Body1: "Ihre Bestellung",
      TableTitle: "Übersicht",
      name: "1 Monat DoryGo Service",

      delivery: "Lieferung",
      costDelivery: "kostenlos",
      costProduct: "kostenlos",
      total: "Total",
      cost: "0 €",
      terms: "",
      labelSource: "Wie haben Sie von uns gehört?",
      physician: "Arzt/Apotheke",
      friends: "Freunde/Familie",
      other: "Sonstiges",
      labelSalesChannelOther: "Woher?",
      body2: "Sie können das Datum der ersten Lieferung später festlegen.",
      btnOrderTxt: "DoryGo Bestellen",
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
      labelVoucherCode: "Gutscheincode",
      errorVoucherInvalid: "Code ungültig",
      buttonTxtVoucher: "Code überprüfen",
      buttonTxtVoucherValid: "Code gültig",
      nameVoucher3Months: "Aktion: +2 Monate",
      costProductVoucher3Months: "kostenlos",
      nameVoucherVisana: "Aktion: 3 Monate",
      costProductVisana: "kostenlos",
    }

  },
  AuthForm: {
    Login: {
      login: "Bitte melden Sie sich an",
      labelEmail: "E-Mail",
      labelPassword: "Passwort",
      btnLoginTxt: "Anmelden",
      errorEmail: "E-Mail nicht korrekt",
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
      errorWrongPassword: "Passwort oder E-Mail ungültig",
      errorTooManyRequests:
        "Der Zugriff auf dieses Konto wurde aufgrund zu vielen fehlgeschlagenen Anmeldeversuche vorübergehend deaktiviert. Bitte versuchen Sie es später erneut oder setzten Sie Ihr Passwort zurück.",
      errorUnknown: "Es ist ein unerwarteter Fehler aufgetreten",
      forgottenPassword: "Ich habe mein Passwort vergessen"
    },
    AuthorizationLetter: {
      PhysicianTitle: "Ihre Ärzte",
      PhysicianBody1:
        "Bitte geben Sie die Namen aller Ärzte an, die Ihnen momentan Medikamente verschrieben haben. Diese benötigen wir, um Ihre Rezepte anzufordern.",
      PhysicianName: "Arzt",
      labelPhysicianName: "Vorname Nachname",
      labelPhysicianLocation: "Ort",
      btnAddPhysicians: "Arzt hinzufügen",
      btnRemovePhysicians: "Arzt entfernen",
      btnOpenAuthForm: "Zur Vollmacht",
    },
  },
  ResetPassword: {
    Header: "Passwort zurücksetzen",
    labelEmail: "E-Mail",
    SuccessMessage: "Eine E-Mail mit einem Link, um das Passwort zurück zu setzten, wurden an die angegebene Adresse gesendet."
  },
  Errors: {
    errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
    errorEmail: "E-Mail nicht korrekt",
  },
  Login: {
    login: "Bitte melden Sie sich an",
    labelEmail: "E-Mail",
    labelPassword: "Passwort",
    btnLoginTxt: "Anmelden",
    errorEmail: "E-Mail nicht korrekt",
    errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
    errorWrongPassword: "Passwort oder E-Mail ungültig",
    errorTooManyRequests:
      "Der Zugriff auf dieses Konto wurde aufgrund zu vielen fehlgeschlagenen Anmeldeversuche vorübergehend deaktiviert. Bitte versuchen Sie es später erneut oder setzten Sie Ihr Passwort zurück.",
    errorUnknown: "Es ist ein unerwarteter Fehler aufgetreten",
    forgottenPassword: "Ich habe mein Passwort vergessen"
  },
  subscription: {
    signup: {
      title: "Konto erstellen",
      labelFirstName: "Vorname",
      labelLastName: "Nachname",
      labelTitle: "Anrede",
      optionMale: "Herr",
      optionFemale: "Frau",
      labelTelephone: "Telefon",
      labelEmail: 'Email',
      labelEmailConfirm: 'Email wiederholen',
      labelAcceptNews: "Ich möchte über Neuigkeiten und Angebote informiert werden.",
      labelAcceptTerms: "Ich stimme den Nutzungsbedingungen zu.",
      labelAcceptData: "Ich stimme der Verarbeitung der personenbezogenen Daten gemäss der Datenschutzerklärung zu.",
      labelHasEmail: "Ich habe eine Email",
      labelNoEmail: "Ich habe keine Email",
      btnCreateAccountTxt: "Konto anlegen",
      labelNoEmailText: "Achtung: Ohne Email-Adresse haben Sie keinen Zugriff auf die DoryGo App.",
      labelHasAccount: "Sie haben bereits ein Konto",
      labelHasAccountLink: "Einloggen",
      labelPassword: "Passwort",
      titleLogin: "Einloggen",
      btnLoginTxt: "Anmelden",
      labelForgetPassword: "Passwort vergessen?",
      btnLoginBack: "Zurück",
      labelInsurance: "Versicherung",
      labelIdentificationNumber: "Kennummer",
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
      errorEmail: "E-Mail ungültig",
      errorPasswordInvalid:
        "Das Passwort muss mindestens 8 Zeichen lang sein, sowie mindestens einen Grossbuchstaben, Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
      errorPasswordMissmatch: "Passwörter stimmen nicht überein",
      errorPermissionRequired: "Zustimmung benötigt um fortzufahren",
      errorPhoneInvalid: "Ihre Telefonnummer ist ungültig",
      labelPassword: "Passwort",
      labelPasswordRepeat: "Passwort wiederholen",
      errorWrongPassword: "Passwort oder E-Mail ungültig",
      errorTooManyRequests:
        "Der Zugriff auf dieses Konto wurde aufgrund zu vielen fehlgeschlagenen Anmeldeversuche vorübergehend deaktiviert. Bitte versuchen Sie es später erneut oder setzten Sie Ihr Passwort zurück.",
      errorUnknown: "Es ist ein unerwarteter Fehler aufgetreten",
      errorEmailExists: "Diese Mailadresse wird bereits verwendet",
      ButtonContinue: "Weiter",
      labelCreateAccount: "Konto erstellen", 
      Announce: "Anmelden",
      PharmacyElections: "Apotheke wählen", 
      OrderOverview: "Bestellübersicht",
      Managed: "Geschafft",
      labelBirthdate: 'Geburtsdatum',
      errorValidDate: "Bitte wählen Sie ein gültiges Datum.",
    }, 
    order: {
      title1: "Kanton wählen",
      title2: "Wählen Sie Ihre Apotheke",
      title3: "Versicherungskarte",
      labelCanton: "Kanton",
      labelPharmacy: "Apotheke",
      labelSubmit: "Zur Bestellübersicht",
      btnLoginBack: "Zurück",
      labelDescriptionText: "Ihre Apotheke benötigt folgende Informationen zu Ihrer Versicherung, um die Medikamente und den Service abrechnen zu können.",
      labelCardHolder: "Name der Versicherung",
      labelCardNumber: "Kartennummer",
      labelOrderBtn: "Bestellung abschliessen",
      title4: "Ihre Bestellung",
      title5: "Persönliche Informationen",
      title6: "Ihre Apotheke",
      errorInvalidCardNumber:
      "Kartennummer ungültig. Die Nummer muss 20 Zeichen lang sein und darf nur Zahlen und Leerzeichen enthalten.",
      text1: "Regelmässige Lieferungen oder Abholung",
      text2: "Einnahmeerinnerungen",
      text3: "Box für unterwegs",
      text4: "CHF kostenlos",
      text5: "CHF 21.60 pro Woche ohne Wochendosierrezept ",
      labelInsurance: "Versicherung",
      labelIdentificationNumber: "Kennummer",
      errorValueNeeded: "Bitte füllen Sie dieses Feld aus",
    },
    final: {
      title: "Vielen Dank für Ihre Bestellung!",
      labelButton1: "Vollmacht erteilen",
      labelButton2: "Fertig",
      title2: "Jetzt fehlen nur noch Ihre Medikamentenrezepte.",
      title3: "Wie möchten Sie die Rezepte einreichen?",
      labelSwitch1: "In Person",
      labelSwitch2: "Per Post",
      labelSwitch3: "Ich möchte das DoryHealth überlassen",
      surveyTitle: "Wie haben Sie von uns erfahren?",
      option1: "Meine Apotheke ",
      option2: "Mein Arzt",
      option3: "Flyer, den ich in meinem Briefkasten erhalten habe",
      option4: "Online, nach einer Suche",
      option5: "Les Invisibles/ Tamara",
      option6: "Jemand hat mir von DoryHealth erzählt",
      question: "Wer?",
      error: "Der Eingabetext muss mindestens 2 Zeichen lang sein"
    }
  }
}
