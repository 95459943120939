import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import urlSelect from "../utils/helpfunctions"
import { deDE } from "../lang/de-DE"
import { deCH } from "../lang/de-CH"
import { frCH } from "../lang/fr-CH"

const FooterWrapper = styled.footer`
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 3rem;
  background-color: var(--dark-grey);
  padding-left: 10vw;
  padding-right: 10vw;

  

  & nav {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    

    .footer-col {
      flex: 1 auto;
      display: inline-flex;
      flex-direction: column;
      
    }
  }

  & a {
    color: var(--color-white);
    font-weight: bold;

    &:hover {
      color: var(--light-grey);
    }
  }

  .footer-col > p {
    margin: 0;
  }

  .footer-title {
    margin: 0 0 0.5rem;
    font-size:1rem;
    color: var(--color-white);
    font-weight:bold;
    font-family: Arial, Helvetica, sans-serif;
  }

  .footer-item {
    color: var(--color-white);
    font-size:0.9rem;

    & a {
      padding: 0.25rem 0;
      display: block;
      font-weight: normal;
      font-family: Arial, Helvetica, sans-serif;
    }


    @media(min-width:800px){
      font-size:0.8rem;

    }
  }

  .footer-heart {
    color: var(--color-red);
  }

  .footer-item-text {
    padding: 0.1rem 0;
    color: var(--color-white);
  }

  .footer-header {
    order: 1;
    margin: 0 0.25rem;
    margin-right: 0.25rem;
    padding: 0.25rem;
  }

  .footer-column-items {
    grid-template-columns: 1fr;
    display: grid;
  }

  @media (max-width: 564px) {
    .footer-col {
      width: 100%;
    }
    .footer-title{
        margin-top:0.5rem;
        margin-bottom:0.25rem;

    }
  }
`
const StoreIcon = styled.div`
display: flex;
flex-direction: column;
align-items:center;

`
const StoreBadge = styled.div`
margin:0.125rem 0;

`

export default function Footer(props) {

  const data = useStaticQuery(graphql`
query {
  Google: file(name: { eq: "google-play-badge" }, extension: { eq: "png" }) {
    childImageSharp {
      gatsbyImageData(
        height: 30
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
  iOS_Badge: file(name: { eq: "apple_badge" }, extension: { eq: "png" }) {
    childImageSharp {
      gatsbyImageData(
        height: 30
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
}
`)

  let txt;
  if (props.language === "de-CH") {
    txt = deCH.footer;
  }
  else if (props.language === "fr-CH") {
    txt = frCH.footer;
  }
  else if (props.language === "de-DE") {
    txt = deDE.footer;
  }
  else {
    txt = deDE.footer;  // here to prevent ssr issue when txt is uninitialized... no idea. fuck that stuff
    console.error("Unexpected path in Footer " + props.language)
  }

  const FooterLinks = txt;

  const FooterItem = ({ item }) => {
    if (item.url.startsWith("/")) {
      return (
        <span className="footer-item">
          <Link className="footer-link" to={item.url}>
            {item.label}
          </Link>
        </span>
      )
    }
    return (
      <span className="footer-item">
        <a className="footer-link" href={item.url} rel={item.rel}>
          {item.label}
        </a>
      </span>
    )
  }

  const FooterColumn = ({ column }) => {
    return (
      <div className="footer-col">
        <h3 className="footer-title" key={column.sectionName}>
          {column.sectionName}
        </h3>
        <div className="footer-column-items">
          {column.links.map((item, i) => {
            return <FooterItem item={item} key={`footer-column-item-${i}`} />
          })}
        </div>
      </div>
    )
  }

  return (

    <FooterWrapper>
      <nav>
        <div className="footer-col">
          <h3 className="footer-title">
            DoryHealth © {new Date().getFullYear()}
          </h3>
        </div>

        {FooterLinks.map((column, i) => {
          return <FooterColumn column={column} key={`footer-column-${i}`} />
        })
        }


        <StoreIcon>
          <h3 className="footer-title">
            App herunterladen
          </h3>
          <a href="https://play.google.com/store/apps/details?id=com.doryhealth.app&referrer=utm_source%3Dweb" target="_blank" rel="noreferrer noopener">
            <StoreBadge>
              <GatsbyImage image={data.Google.childImageSharp.gatsbyImageData} alt="Download App on Android" />
            </StoreBadge>
          </a>
          <a href="https://apps.apple.com/us/app/dorygo-einfach-bequem/id1569657963" target="_blank" rel="noreferrer noopener">
            <StoreBadge>
              <GatsbyImage image={data.iOS_Badge.childImageSharp.gatsbyImageData} alt="Download App on iOS" />
            </StoreBadge>
          </a>
        </StoreIcon>

      </nav>
    </FooterWrapper>
  )
}
