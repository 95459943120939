export const frCH = {
  SEO: {
    title: "DoryGo - Gérer ses médicaments en toute commodité",
    description: "Votre solution complète pour vos traitements. Nous gérons vos ordonnances, livrons vos médicaments pré-triés directement chez vous, et créons des rappels pour la prise de pilules",
  },
  home: {
    Header: {
      Slogan1: "Nous simplifions la gestion vos médicaments",
      Slogan2: "<ul> <li>Vos médicaments triés et livrés à domicile </li> <li>Application intuitive pour gérer médicaments et prescriptions  </li> <li>Boîte à médicaments pratique et portable</li> </ul>",
      Recommendation: "Recommandé par: ",
      RecommendationDZ: "Votre interlocuteur compétent pour tous ceux qui sont concernés par le diabète dans le canton de Zurich",
      RecommendationEpi: "l'organisation de patients pour les personnes atteintes d'épilepsie et leurs proches",
      ButtonCTAText: "Découvrez si vous avez gratuitement droit à notre service",
      ButtonTxt: "Tester maintenant",
    },
    Overview: {
      Title1: "Service &shy; de livraison",
      Title2: "Boite à médicaments",
      Title3: "Application",
      B1: "Vos médicaments sont pré-triés par des pharmaciens expérimentés dans un semainier et livrés régulièrement directement à votre domicile",
      B2: "Avec notre DoryGo Box compact, vous avez toujours avec vous vos besoins hebdomadaires en médicaments grâce à ses 7x3 compartiments</br>Elle est discrète, tient confortablement dans la main et vos médicaments restent protégés.",
      B3: "L'application DoryGo vous rappelle de prendre vos médicaments. Tous les rappels sont déjà configurés pour vous par la pharmacie. <br/>Il suffit d'informer la pharmacie de tout changement dans l'application.",
      LearnMore: "En savoir plus",

    },
    HowTo: {
      Header: "Notre service - tout en simplicité:",
      S1: "1. Vous vous inscrivez",
      B1: "Il vous suffit de vous inscrire en ligne. Ensuite, avant de devoir commander quelque chose directement dans l'application",
      S2: "2. Nous trions vos médicaments",
      B2: "La pharmacie pré-trie vos médicaments en fonction de l'heure de prise, emballés en toute sécurité pour toute la semaine",
      S3: "3. Nous livrons à votre domicile",
      B3: "Vous recevez régulièrement vos médicaments - toujours à temps",
      ButtonCTAText: "Découvrez si vous avez gratuitement droit à notre service",
    },
    Video: {
      Header: "DoryGo expliqué en bref",
    },
    Incentive: {
      Header: "Payé par votre caisse maladie!",
      SubHeader: "Si vous prenez régulièrement 3 médicaments ou plus, votre caisse maladie prend en charge les coûts de DoryGo.",
      ButtonTxt: "Tester maintenant",
    },
    CallToAction: {
      Header: "Payé par votre caisse maladie",
      SubHeader: "Si vous prenez régulièrement trois médicaments ou plus, votre assurance maladie couvrira les frais de DoryGo.",
      ButtonTxt: "Essayer maintenant",
    },
    MainBenefits: {
      Header: "Vos avantages",
      SubHeader: "Nous gérons...",
      F1: {
        Title: "Vos ordonnances",
        Body: "Nous veillons à ce que votre médecin soumette toutes les ordonnances nécessaires à la pharmacie",
      },
      F2: {
        Title: "Vos médicaments",
        Body: "La Pharmacie Centrale d'Olten pré-trie vos médicaments en fonction du moment où vous les prenez et les livre directement à votre domicile.",
      },
      F3: {
        Title: "Votre plan de médication",
        Body: "La pharmacie crée le plan de médicaments pour vous dans l'application DoryGo et configure les rappels pour vous. Les modifications sont automatiquement transférées vers l'application.",
      },
    },
    BoxExplained: {
      Header: "Comment fonctionne la boîte?",
      Body:
        "Vous pouvez mettre une semaine entière avec des médicaments pré-triés dans la boîte en un seul mouvement. De cette façon, le médicament est protégé de manière optimale et peut être facilement extrait en pressant à travers le semainier.",
    },
    Quotes: {
      Quote1: {
        Body: "Service fantastique. Les livraisons régulières et l'application facilitent grandement la vie. ",
        Author: "Stéphanie, 58",
      },
      Quote2: {
        Body: "Le service est vraiment génial ! C'est un grand soulagement. Je l'ai recommandé à plusieurs dont mon médecin généraliste. ",
        Author: "Sandra, 38",
      },
      Quote3: {
        Body: "Je vois un grand potentiel dans le système DoryGo pour améliorer le succès du traitement et en même temps faciliter la vie de mes patients. ",
        Author: "Dr. Alexander Meisel, oncologue, Hôpital universitaire de Zurich"
      },
    },
    FAQ: [
      {
        Title: "Puis-je également recevoir des médicaments pendant plus de 2 semaines à la fois?",
        Body: "Dans presque tous les cas, les médicaments peuvent également être délivrés pendant une période plus longue. Votre pharmacie se fera un plaisir de vous aider avec cette question. Vous pouvez trouver les coordonnées dans l'application DoryGo sous 'Votre pharmacie'.",
      },
      {
        Title: "Que se passe-t-il si un médicament n'est pas disponible?",
        Body: "En cas de rupture d'approvisionnement, la pharmacie passera à un générique après consultation avec vous et votre médecin.",
      },
      {
        Title: "Que dois-je faire si mes médicaments changent?",
        Body: "Vous pouvez communiquer les modifications à la pharmacie en quelques clics.",
      },
      {
        Title: "Puis-je également obtenir des injections/crèmes/etc à travers votre service?",
        Body: "Votre pharmacie se fera un plaisir de livrer séparément les médicaments qui ne rentrent pas dans la boîte. Vous serez également rappelé des prescriptions d'admission et d'expiration via l'application.",
      },
      {
        Title: "Qui puis-je contacter si j'ai des questions?",
        Body: "Vous pouvez nous contacter au +41 44 551 43 74 ou hello@dorygo.ch. Votre pharmacie se fera un plaisir de vous aider si vous avez des questions sur vos médicaments. Vous pouvez trouver les coordonnées de votre pharmacie dans l'application DoryGo sous 'Votre pharmacie'.",
      },
      {
        Title: "Comment les médicaments seront-ils livrés?",
        Body: "Les médicaments sont livrés par la poste toutes les deux semaines. La livraison est gratuite pour vous.",
      },
      {
        Title: "Est-ce que toutes les caisses maladie couvrent les frais de DoryGo?",
        Body: "Si vous prenez régulièrement trois médicaments ou plus, les frais de DoryGo sont couverts par toutes les caisses d'assurance maladie via votre assurance de base.",
      },
    ],
    QuickForm: {
      BackToHome: "Page d'accueil",
      HowManyMeds: "Combien de médicaments prenez-vous régulièrement par semaine?",
      InsurerCovers: "Super! Votre caisse maladie prend les coûts de DoryGo en charge.",
      InsurerCoversNot: "Parce que vous prenez moins 3 médicaments régulièrement, votre assurance ne prend malheureusement pas en charge le service DoryGo",
      ButtonBack: "Retour",
      ButtonContinue: "Continuer",
      ButtonTxtIllPay: "Je prends les coûts de Dory en charge moi-même.",
      ButtonCheck: "Vérifier",
    },
    PharmacyPartner: {
      SectionHeader: "Des pharmacies expérimentés",
      SectionBody: 'Vous recevez vos médicaments des pharmaciens expérimentés de la <a href="http://www.apotheke-olten.ch/" target="_blank"rel="noreferrer noopener">Pharmacie Centrale d\'Olten </a>. Leur vaste expérience en emballage et envoi de semainiers garantit que vos médicaments sont entre les meilleures mains. ',
    },
    TestOrContact: {
      Partners: "Nos partenaires",
    },
  },
  footer: [
    {
      sectionName: 'Général',
      links: [
        {
          label: 'Accueil',
          url: '/ch-fr/',
        },
        {
          label: 'Comment ça marche',
          url: '/ch-fr/vorteil',
        },
        {
          label: 'Prix',
          url: '/ch-fr/preise',
        },
      ],
    },
    {
      sectionName: 'À propos de nous',
      links: [
        {
          label: 'À propos de nous',
          url: '/ch-fr/about',
        },
        {
          label: 'Contact',
          url: '/ch-fr/about',
        },
      ],
    },
    {
      sectionName: 'Personnel de santé',
      links: [
        {
          label: 'Pharmacies',
          url: '/ch-fr/apotheken',
        },
        {
          label: 'Médecins',
          url: '/ch-fr/aerzte',
        },
      ],
    },
    {
      sectionName: 'Suivez-nous sur',
      links: [
        {
          label: 'Facebook',
          url: 'https://www.facebook.com/DoryHealth-100218411671110',
          rel: 'external',
        },
        {
          label: 'LinkedIn',
          url: 'https://www.linkedin.com/company/doryhealth/',
          rel: 'external',
        },
      ],
    },
    {
      sectionName: 'Mentions légales',
      links: [
        {
          label: 'Mentions légales',
          url: '/impressum',
        },
        {
          label: 'Accord de confidentialité',
          url: '/ch-fr/datenschutzvereinbarung',
        },
        {
          label: 'Conditions d\'utilisation',
          url: '/ch-fr/nutzungsbedingungen',
        },
      ],
    },
  ],

  features: {
    Box: {
      Slogan: "Vos médicaments toujours avec vous",
      Category1: "Poids",
      Value1: "116g",
      Category2: "Taille",
      Value2: "15x11x3 cm",
      Category3: "#Fois",
      Value3: "7x3",
      Category4: "Pilules par point dans le temps ",
      Value4: "jusqu'à 7",
      F1: {
        Title: "Retrait facile",
        Body: "Grâce au matériau d'emballage souple et à la conception optimisée, le médicament peut être expulsé avec peu de force.",
      },
      F2: {
        Title: "Remplissage rapide",
        Body: "La boîte est remplie en quelques secondes. Pack vide sorti - pack plein rentré.",
      },
      F3: {
        Title: "Protège vos médicaments",
        Body: "Grâce à la combinaison de la boîte rigide et de l'emballage souple, vos médicaments sont protégés de manière optimale.",
      },
    },
    App: {
      header: "Vos ordonnances toujours sous contrôle",
      F1: {
        Title: "Rappels de médicaments",
        Body: "Nous vous rappelons de manière fiable de prendre vos médicaments.",
      },

      F2: {
        Title: "Établi par Pharmacie",
        Body: "Votre programme de prise de médicaments et vos rappels sont configurés et gérés pour vous par la pharmacie.",
      },
      F3: {
        Title: "Vos données sont en sécurité",
        Body: "Nous apprécions votre vie privée. C'est pourquoi vos données sont stockées sous forme cryptée en Europe conformément aux lois allemandes et suisses sur la protection des données.",
      },
      F4: {
        Title: "Rappel d'ordonnances",
        Body: "Nous vous rappellerons en temps utile que votre ordonnance est sur le point d'expirer.",
      },
      F5: {
        Title: "Demander des ordonnances facilement",
        Body: "Vous pouvez demander votre ordonnance en quelques clics. Si vous le souhaitez, nous pouvons également le faire pour vous. ",
      },
    },
    DeliveryService: {
      Header: "Livraison directement à votre domicile",
      Title1: "Pré-triée",
      S1: {
        B1: "Par une pharmacie Suisse",
        B2: "Retrait facile",
        B3: "Dans des packs de recharge pratiques ",
      },
      Title2: "Livraison régulière",
      S2: {
        B1: "Livraison par la poste",
        B2: "Toutes les 2-3 semaines",
        B3: "Prestation vacances",
      },
      Title3: "Assisté(e) personnellement",
      S3: {
        B1: "Nos pharmacies partenaires se feront un plaisir de vous aider si vous avez des questions",
        B2: "Par téléphone, e-mail ou chat",
      },
      Slogan2: "Ces pharmacies vous approvisionnent",
      Slogan3: "Plus de temps pour les belles choses de la vie",
    },
    CallToAction: {
      Header: "Essayez DoryGo maintenant",
      SubHeader: "Si vous prenez régulièrement trois médicaments ou plus, votre assurance maladie couvrira les frais de DoryGo.",
      ButtonTxt: "Essayer dès maintenant",
      Unsure: "Pas encore sûr/sure?",
      AskConsult: "Demandez une consultation",
    },
    FAQ: [
      {
        Title: "Puis-je également recevoir des médicaments pendant plus de 2 semaines à la fois?",
        Body: "Dans presque tous les cas, les médicaments peuvent également être délivrés pendant une période plus longue. Votre pharmacie se fera un plaisir de vous aider avec cette question. Vous pouvez trouver les coordonnées dans l'application DoryGo sous 'Votre pharmacie'.",
      },
      {
        Title: "Quelle pharmacie livre mes médicaments?",
        Body: "Vos médicaments seront livrés par l'Apothèque centrale d'Olten.",
      },
      {
        Title: "Que se passe-t-il si un médicament n'est pas disponible?",
        Body: "En cas de rupture d'approvisionnement, la pharmacie passera à un générique après consultation avec vous et votre médecin.",
      },
      {
        Title: "Que dois-je faire si mes médicaments changent?",
        Body: "Vous pouvez communiquer les modifications à la pharmacie en quelques clics.",
      },
      {
        Title: "Combien coûte la boîte?",
        Body: "La boîte est incluse dans notre service.",
      },
      {
        Title: "Les boîtes sont-elles disponibles dans d'autres couleurs et tailles?",
        Body: "La boîte n'est actuellement disponible qu'en une seule couleur et taille.",
      },

      {
        Title: "Puis-je également obtenir des injections/onguents/etc du service?",
        Body: "Votre pharmacie se fera un plaisir de livrer séparément les médicaments qui ne rentrent pas dans la boîte. Vous serez également rappelé des prescriptions d'admission et d'expiration via l'application.",
      },
      {
        Title: "Qui puis-je contacter si j'ai des questions?",
        Body: "Vous pouvez nous contacter au +41 44 551 43 74 ou hello@dorygo.ch. Votre pharmacie se fera un plaisir de vous aider si vous avez des questions sur vos médicaments. Vous pouvez trouver les coordonnées de votre pharmacie dans l'application DoryGo sous 'Votre pharmacie'.",
      },
      {
        Title: "Comment puis-je soumettre mes ordonnances?",
        Body: "Vous pouvez soumettre vos ordonnances sous forme de photo via l'application/e-mail ou par courrier. Ou vous pouvez simplement nous délivrer une procuration de prescription. Nous veillerons alors à ce que votre médecin soumette toutes les ordonnances à temps à la pharmacie.",
      },
      {
        Title: "Comment fonctionne l'autorisation de prescription?",
        Body: "Vous avez la possibilité de nous délivrer une procuration de prescription en ligne. Ensuite, nous nous assurerons que votre médecin soumet toutes les ordonnances de notre pharmacie partenaire.",
      },
      {
        Title: "Livrez-vous également les médicaments à l'étranger?",
        Body: "Les médicaments ne peuvent être livrés qu'en Suisse. Cependant, dans la plupart des cas, les médicaments peuvent être livrés plus longtemps à l'avance.",
      },
      {
        Title: "Comment les médicaments seront-ils livrés?",
        Body: "Les médicaments sont livrés par la poste toutes les deux semaines. La livraison est gratuite pour vous.",
      },
      {
        Title: "Qu'est-ce qui est inclus dans le service?",
        Body: "Le service DoryGo comprend la boîte à médicaments, l'application et le service de livraison incluant tous les frais de livraison.",
      },
    ],
  },
  prices: {
    Header: "Payé par votre caisse d'assurance maladie",
    Benefit1: "Annulable à tout moment",
    Benefit2: "Pas de frais cachés",
    Benefit3: "Qualité Suisse",
    BtnTxt: "Inscrivez-vous maintenant",
    ButtonTxt: "Essayer dès maintenant",
    Unsure: "Pas encore sûr/sure?",
    AskConsult: "Demandez une consultation",
    Exp1: {
      Title: "Assuré par votre caisse d'assurance maladie",
      Body: "Les frais de CHF 21.60 par semaine sont pris en charge par toutes les caisses-maladie pour autant que les points suivants soient respectés: ",
      LI1: "Vous prenez régulièrement au moins 3 médicaments",
      LI2: "Votre médecin vous prescrit un système de dosage hebdomadaire",
    },
    Exp2: {
      Title: "Système de dosage hebdomadaire",
      Body: " Votre médecin peut vous prescrire un système de dosage hebdomadaire si vous prenez 3 médicaments ou plus. <br/><br/>Nous pouvons demander cette ordonnance pour vous à votre médecin. ",
    },
    Exp3: {
      Title: "Date de début flexible",
      Body: "Vous pouvez librement choisir la date de la première livraison et l'ajuster à tout moment.",
    },

    Onboarding: {
      Header: "Comment se connecter",
      Title1: "Créez votre compte sans engagement.",
      Body1: "Créer un compte sans engagement.",
      Title2: "Configurer le service",
      Body2: "Ici, vous définissez votre date de début et répondez à quelques courtes questions pour la pharmacie.",
      Title3: "Soumettre des ordonnances",
      Body3: "Nous veillons à ce que votre médecin soumette toutes les ordonnances à notre pharmacie partenaire suisse. <br/>Bien sûr, vous pouvez également soumettre vos propres ordonnances via l'application ou la poste.",
      Title4: "C'est parti!",
      Body4: "Vous recevrez vos médicaments triés et la boîte DoryGo à temps pour la date de début sélectionnée.",
    },
    FAQ: [

      {
        Title: "Qui puis-je contacter si j'ai des questions?",
        Body: "Vous pouvez nous contacter au +41 44 551 43 74 ou hello@dorygo.ch. Votre pharmacie se fera un plaisir de vous aider si vous avez des questions sur vos médicaments. Vous pouvez trouver les coordonnées de votre pharmacie dans l'application DoryGo sous 'Votre pharmacie'.",
      },

      {
        Title: "Combien coûte le service?",
        Body: "Si vous prenez 3 médicaments ou plus par semaine, les frais seront pris en charge par votre caisse d'assurance maladie s'ils sont prescrits par un médecin. Vous pouvez demander cette ordonnance directement via l'application ou auprès de votre médecin de famille. Si vous ne recevez pas d'ordonnance, il n'y a pas de frais.",
      },
      {
        Title: "Est-ce que toutes les caisses maladie couvrent les frais de DoryGo?",
        Body: "Tant que vous prenez régulièrement trois médicaments ou plus, les frais de DoryGo seront couverts par toutes les caisses d'assurance maladie via votre assurance de base.",
      },
      {
        Title: "Combien coûte le service si je prends moins de 3 médicaments?",
        Body: "Si vous prenez moins de 3 médicaments, vous devez payer en privé les frais de pharmacie de CHF 21.60 par semaine. Dans ce cas, la pharmacie demandera à nouveau votre consentement.",
      },

      {
        Title: "Comment le service est-il facturé?",
        Body: "Nos pharmacies partenaires règlent les frais de la prestation et des médicaments directement auprès de votre mutuelle.",
      },
      {
        Title: "Qu'est-ce qui est inclus dans le service?",
        Body: "Le service DoryGo comprend la boîte à médicaments, l'application et le service de livraison incluant tous les frais de livraison.",
      },

      {
        Title: "Combien coûte l'expédition des médicaments?",
        Body: "La livraison des médicaments est gratuite pour vous.",
      },


      {
        Title: "Comment puis-je annuler le service?",
        Body: "Vous pouvez vous désinscrire du service à tout moment en envoyant un e-mail à hello@doryhealth.com. Le service fonctionne jusqu'à ce que vous l'annuliez ou arrêtiez de soumettre une ordonnance. ",
      },

      {
        Title: "Que se passe-t-il si je ne suis pas satisfait du service?",
        Body: "Vous pouvez annuler le service à tout moment.",
      },
      {
        Title:
          "j'ai encore des médicaments à la maison. Puis-je démarrer le service plus tard?",
        Body: "Vous pouvez choisir librement la date de début.",
      },
    ],
  },
  Pharmacy: {
    Slogan: "Notre offre pour les pharmacies",
    Contact: {
      Slogan: "Test",
      Header: "Êtes-vous intéressé?",
      SubText: "Contactez-nous via le formulaire de contact et nous vous répondrons dans les plus brefs délais.",
      Pharmacy: "pharmacie*",
      Name: "Name*",
      Email: "E-mail*",
      Phone: "Numéro de téléphone*",
      Message: "Message*",
      Mandatory: "*champ obligatoire",
      BtnTxt: "je suis intéressé",
    },
    Benefits: {
      SectionHeader: "Ce que nous proposons",
      SectionSubHeader: "Un partenariat avec nous vous offre de nombreux bénéfices: ",
      Benefit1: {
        Header: "Acquisition de nouveaux clients",
        Body: "Nous acquérons de nouveaux clients pour vous. <br /> <br />",
      },
      Benefit2: {
        Header: "Amélioration de la fidélisation des clients",
        Body: "Avec notre service, vous améliorez l'expérience de vos clients et augmentez la fidélité de vos clients.",
      },
      Benefit3: {
        Header: "Portail de la pharmacie",
        Body: "Nous mettons gratuitement à votre disposition un portail de pharmacie intuitif pour gérer vos clients DoryGo.",
      },
      Benefit4: {
        Header: "Rémunération",
        Body: "Vous pouvez facturer les services rendus selon la LOA.",
      },
      ReqHeader: "Ce que nous recherchons",
      ReqSubHeader: "Nous recherchons des pharmacies répondant aux exigences suivantes: ",
      Req1: {
        Header: "Licence de vente par correspondance",
        Body: "Nos pharmacies partenaires doivent disposer d'une licence de vente par correspondance afin que nos clients puissent être livrés par la poste dans tout le pays.",
      },
      Req2: {
        Header: "Service de semainiers",
        Body: "Nous supposons que vous disposez de l'infrastructure et des licences nécessaires pour accepter une commande de semainiers et l'exécuter à temps.",
      },
    },
  },
  about: {
    CompanyDetails: {
      Header: "À propos de nous",
      Body: "DoryHealth AG est une entreprise de Zurich et a été fondée en 2020 par deux biochimistes de l'ETH. Nous nous sommes fixé pour objectif de faciliter au maximum la vie des personnes atteintes de maladies chroniques. Nous travaillons avec des pharmacies et des médecins expérimentés de Suisse et d'Allemagne pour offrir à nos clients des soins optimaux et pratiques.",
    },
    Team: {
      Header: "L'équipe",
      SG: {
        Nom: "Stephan Gath",
        Role: "Fondateur - PDG",
        Body: "Stephan est responsable du développement commercial et des finances.",
      },
      JC: {
        Nom: "Jérôme Castane",
        Role: "Fondateur - COO",
        Body: "Jerome Castan est diplômé en physique de l'ETH Zurich et est responsable du développement et de la production des produits.",
      },
      BS: {
        Nom: "Barbara Staehelin",
        Role: "Conseiller",
        Body: "Barbara Staehelin soutient l'équipe fondatrice dans le développement stratégique grâce à ses nombreuses années d'expérience dans le secteur de la santé.",
      },
      HV: {
        Nom: "Dr. Heiko Visarius",
        Role: "Conseiller",
        Body: "Heiko Visarius a de nombreuses années d'expérience dans l'industrie des dispositifs médicaux et soutient l'équipe de DoryHealth dans le cadre du coaching d'Innosuisse.",
      },
    },
  },
  ContactMe: {
    name: "ContactMe",
    subject: "ContactMe Website",
    action: "",
    successMessage: "Merci pour votre intérêt. Nous nous manifestons auprès de vous dans les plus brefs délais.",
    errorMessage: "Une erreur est survenue. Essayez à novueau ou écrivez-nous un e-mail directement",
    WeHelp: "Nous nous faisons un plaisir de vous aider",
    WeContactFast: "Veuillez entrer vos coordonnées. Nous nous manifestons auprès de vous dès que possible. ",
    BtnSend: "Envoyer",
    placeholder: "Est-ce que vous souhaitez ajouter quelque chose?",
  },
  Onboarding: {
    Intro: {
      Title: "Bienvenue à DoryGo !",
      Body: "Vous pouvez maintenant vous connecter à l'application avec votre adresse e-mail et le mot de passe que vous avez choisi. <br/><br/> Afin que vous receviez votre première livraison, votre pharmacie a besoin de quelques informations. <br/><br/>La configuration du service ne prend que quelques minutes. ",
      ButtonForwardTxt: "Configurer le service",
      ButtonCntLater: "Continuer plus tard dans l'application",
    },
    Medication: {
      medicationTitle: "Choose start date",
      txtMedicationDelivery: "Quand avez-vous besoin de la première livraison de médicaments?",
      labelStartDate: "Date de début",
      medicationExplanation: "Vous recevrez la première livraison une semaine avant la date indiquée.",
      btnNext: "Suivant",
      btnBack: "Retour",
      errorStartDate: "Veuillez sélectionner une date valide.",
    },
    Address: {
      addressTitle: "Adresse de livraison",
      labelAddress: "Rue et numéro",
      labelZipCode: "Code postal",
      labelCity: "Ville",
      labelCountry: "Pays",
      optionSwitzerland: "Suisse",
      optionGermany: "Allemagne",
      labelPhone: "Numéro de téléphone",
      btnNext: "Suivant",
      btnBack: "Retour",
      errorValueNeeded: "Veuillez remplir ce champ",
      errorZipCodeInvalid: "Code postal invalide",
      errorInputInvalid: "Entrée invalide",
    },
    Pharmacy: {
      txtExplanation: "La pharmacie a besoin de ces informations pour pouvoir traiter votre commande.",
      labelGender: "Genre",
      optionMale: "Homme",
      optionFemale: "Femme",
      txtPregnant: "Êtes-vous enceinte ou allaitez-vous?",
      labelPregnant: "Enceinte/Allaitement?",
      optionPregnant: "Oui, je suis enceinte",
      optionBreastfeeding: "Oui, j'allaite",
      optionYes: "Oui",
      optionNo: "Non",
      txtDiseases: "Pour quelle(s) maladie(s) êtes-vous sous traitements?",
      labelDiseases: "Maladies",
      txtAllergies: "Avez-vous des allergies?",
      labelAllergies: "Allergies",
      txtWhichAllergies: "Lequel?",
      labelWhichAllergies: "Quelles allergies?",
      txtOtherMedications: "Prenez-vous d'autres médicaments, remèdes à base de plantes, vitamines ou similaires?",
      labelOtherMedications: "Autres médicaments",
      labelWhichOtherMedications: "Lequels?",
      btnNext: "Suivant",
      btnBack: "Retour",
      errorValueNeeded: "Veuillez remplir ce champ",
    },
    InsuranceCard: {
      insuranceCardTitle: "Carte d'assurance",
      txtInsuranceExplanation: "Votre pharmacie a besoin des informations suivantes sur votre assurance afin de pouvoir facturer les médicaments et le service.",
      labelInsuranceName: "Nom de l'assurance",
      errorInvalidCardNumber: "Numéro de carte invalide. Le numéro doit comporter 20 caractères et ne contenir que des chiffres et des espaces.",
      labelCardNumber: "Numéro de carte",
      btnNext: "Suivant",
      btnBack: "Retour",
    },
    AuthorizationLetter: {
      Title: "Soumettre des ordonnances",
      Body1: "La pharmacie a besoin des ordonnances pour tous les médicaments et d'une ordonnance pour un système de dosage hebdomadaire de votre part. <br /> <br />",
      SubTitle1: "Laissez-nous faire",
      Body2: "Nous vous offrons la possibilité de nous accorder une procuration. Cela nous permet de faire en sorte que votre médecin soumette les ordonnances nécessaires directement à la pharmacie. <br /> <br /> Bien sûr, vous pouvez également soumettre les ordonnances vous-même.",
      btnSelf: "Soumettez vous-même vos ordonnances",
      btnAuthForm: "Remplir la procuration",
      btnBack: "Retour",
      PhysicianTitle: "Spécifiez les médecins",
      PhysicianBody1: " Nous avons besoin des noms de tous les médecins qui vous ont actuellement prescrit des médicaments.",
      PhysicianName: "Docteur",
      labelPhysicianName: "Prénom Nom",
      labelPhysicianLocation: "Lieu",
      btnAddPhysicians: "Ajouter un médecin",
      btnRemovePhysicians: "Supprimer le médecin",
      btnOpenAuthForm: "Créer la procuration",
    },
    OnboardingCompleted: {
      Title: "FéliQuotes",
      SubTitle: "Vous avez terminé la configuration du service.",
      Body1: " Dans les prochains jours, vous recevrez votre pack de bienvenue avec votre boîte personnelle DoryGo. <br /> <br /> Votre pharmacie a toujours besoin de vos ordonnances de médicaments et d'une ordonnance pour un système de dosage hebdomadaire. <br /> <br />Nous avons brièvement résumé toutes les informations importantes pour vous et votre médecin.",
      btnTxt: "Ouvrir le résumé",
      SubTitle2: "Soumettre des ordonnances",
      Body2: " Veuillez poster les ordonnances originales à la pharmacie de votre choix. <br /> <br /> Vous recevrez l'adresse de livraison dans quelques minutes par e-mail et plus tard dans votre pack de bienvenue.",
      Body3: "Meilleures salutations <br /> Votre équipe DoryGo",
    },
  },
  Success: {
    AuthFormSigned: {
      Title: "Félicitations",
      SubTitle: "Vous avez terminé la configuration du service.",
      Body1:
        "Dans les prochains jours, vous recevrez votre pack de bienvenue avec votre boîte personnelle DoryGo. <br /><br />Ensuite, nous nous assurons que votre médecin soumet toutes les ordonnances à la pharmacie. Dès que les ordonnances seront arrivées, nous vous informerons. ",
      btnTxt: "Ouvrir le résumé",
      Body2: "Nous avons brièvement résumé toutes les informations importantes pour vous et votre médecin.",
      Body3: "Meilleures salutations <br /> Votre équipe DoryGo",
    },
    AuthFormNotSigned: {
      Title: "Félicitations",
      SubTitle: "Vous avez terminé la configuration du service.",
      Body1:
        "Dans les prochains jours, vous recevrez votre trousse de bienvenue avec votre boîte personnelle DoryGo. ",
      btnTxt: "Ouvrir le résumé",
      SubTitle2: "Soumettre des ordonnances",
      Body2:
        "Votre pharmacie a toujours besoin de vos ordonnances de médicaments et d'une ordonnance pour un système de dosage hebdomadaire. <br /> Vous pouvez envoyer les ordonnances à la pharmacie de votre choix via l'application sous forme de photo ou simplement par courrier. <br /> Vous recevrez l'adresse de livraison par e-mail dans quelques minutes.",
      Body3: "Nous avons brièvement résumé toutes les informations importantes pour vous et votre médecin.",
      Body4: "Meilleures salutations <br /> Votre équipe DoryGo",
    },
  },
  Checkout: {
    Login: {
      login: "Connexion",
      labelEmail: "E-mail",
      labelPassword: "mot de passe",
      btnLoginTxt: "Connexion",
      btnCreateAccountTxt: "Créer un compte",
      errorEmail: "E-mail incorrect",
      errorValueNeeded: "Veuillez remplir ce champ",
      errorWrongPassword: "Mot de passe ou e-mail invalide",
      errorTooManyRequests: "l'accès à ce compte a été temporairement désactivé en raison d'un trop grand nombre de tentatives de connexion infructueuses. Veuillez réessayer plus tard ou réinitialiser votre mot de passe.",
      errorUnknown: "Une erreur inattendue s'est produite",
      forgetPassword: "j'ai oublié mon mot de passe",
    },
    CreateAccount: {
      txtWelcome: "Il ne reste que quelques étapes pour votre service DoryGo.",
      subTitle1: "Créer un compte",
      labelEmail: "E-mail",
      labelPassword: "Mot de passe",
      labelPasswordRepeat: "Répéter le mot de passe",
      subTitle2: "Informations générales",
      labelFirstName: "Prénom",
      labelLastName: "Nom de famille",
      labelTitle: "Salutation",
      optionMale: "Monsieur",
      optionFemale: "Madame",
      labelCountry: "Pays",
      labelPreferredLanguage: "Langue préférée",
      optionSwitzerland: "Suisse",
      optionGermany: "Allemagne",
      optionGerman: "Allemand",
      optionFrench: "Français",
      optionEnglish: "Anglais",
      labelBirthdate: "Date de naissance",
      labelAcceptData1: "J'accepte le traitement de mes données personnelles conformément à la ",
      labelAcceptData2: "politique de confidentialité",
      labelAcceptData3: ".",
      labelAcceptTerms1: "J'accepte les",
      labelAcceptTerms2: "conditions d'utilisation",
      labelAcceptTerms3: ".",
      labelAcceptNews: "Je veux être informé des nouveautés et des offres",
      btnLoginTxt: "j'ai déjà un compte",
      btnCreateAccountTxt: "Créer un compte",
      errorEmailExists: "Cette adresse e-mail est déjà utilisée",
      errorUnknown: "Une erreur inattendue s'est produite",
      errorValidDate: "Veuillez sélectionner une date valide.",
      errorPermissionRequired: "Autorisation requise pour continuer",
      errorValueNeeded: "Veuillez remplir ce champ",
      errorEmail: "E-mail invalide",
      errorPasswordInvalid: "Le mot de passe doit comporter au moins 8 caractères et contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
      errorPasswordMissmatch: "Les mots de passe ne correspondent pas",
    },
    Pharmacy: {
      Body1: "Votre pharmacie",
      Body2: "Si vous avez des questions, votre pharmacie se fera un plaisir de vous aider.",
      labelPharmacySwitch: "Je souhaite être approvisionné par la Lünsche Apotheke.",
      btnNextTxt: "Suivant",
      errorAcceptedPharmacy: "Acceptation requise pour continuer",
    },
    FinishOrder: {
      Body1: "Votre commande",
      TableTitle: "Vue d'ensemble",
      name: "DoryGo Service",
      delivery: "livraison",
      costDelivery: "gratuit",
      costProduct: "21.60 Fr. par semaine*",
      total: "Totale",
      cost: "21.60 Fr. par semaine*",
      terms: "*prise en charge par l'assurance maladie à partir de 3 médicaments",
      labelSource: "Comment avez-vous entendu parler de nous?",
      physician: "docteur/pharmacie",
      friends: "amis/famille",
      other: "Autre",
      labelSalesChannelOther: "d'où?",
      body2: "Vous pouvez fixer ultérieurement la date de la première diffusion.",
      btnOrderTxt: "Commander DoryGo",
      errorValueNeeded: "Veuillez remplir ce champ",
      labelVoucherCode: "Code du bon",
      errorVoucherInvalid: "Code invalide",
      buttonTxtVoucher: "Vérifier le code",
      buttonTxtVoucherValid: "Code valide",
      nameVoucherVisana: "+ trois premiers mois gratuits",
      costProductVoucherVisana: "",
      nameVoucher3Months: "Promotion: +2 mois",
      costProductVoucher3Months: "gratuit",
    },
  },
  AuthForm: {
    Login: {
      login: "Veuillez vous connecter",
      labelEmail: "E-mail",
      labelPassword: "mot de passe",
      btnLoginTxt: "Connexion",
      errorEmail: "E-mail incorrect",
      errorValueNeeded: "Veuillez remplir ce champ",
      errorWrongPassword: "Mot de passe ou e-mail invalide",
      errorTooManyRequests: "l'accès à ce compte a été temporairement désactivé en raison d'un trop grand nombre de tentatives de connexion infructueuses. Veuillez réessayer plus tard ou réinitialiser votre mot de passe.",
      errorUnknown: "Une erreur inattendue s'est produite",
      forgetPassword: "j'ai oublié mon mot de passe",
    },
    AuthorizationLetter: {
      PhysicianTitle: "Vos médecins",
      PhysicianBody1: "Veuillez indiquer les noms de tous les médecins qui vous ont actuellement prescrit des médicaments. Nous en avons besoin pour demander vos ordonnances.",
      PhysicianName: "Docteur",
      labelPhysicianName: "FirstName LastName",
      labelPhysicianLocation: "Emplacement",
      btnAddPhysicians: "Ajouter un médecin",
      btnRemovePhysicians: "Supprimer le médecin",
      btnOpenAuthForm: "Pour autorisation",
    },
  },
  ResetPassword: {
    Header: "Réinitialiser le mot de passe",
    labelEmail: "E-mail",
    btnResetTxt: "Réinitialiser le mot de passe",
    SuccessMessage: "Un e-mail avec un lien pour réinitialiser votre mot de passe a été envoyé à l'adresse indiquée.",
  },
  Errors: {
    errorValueNeeded: "Veuillez remplir ce champ",
    errorEmail: "E-mail incorrect",
  },
  Login: {
    login: "Veuillez vous connecter",
    labelEmail: "E-mail",
    labelPassword: "mot de passe",
    btnLoginTxt: "Connexion",
    errorEmail: "E-mail incorrect",
    errorValueNeeded: "Veuillez remplir ce champ",
    errorWrongPassword: "Mot de passe ou e-mail invalide",
    errorTooManyRequests: "L'accès à ce compte a été temporairement désactivé en raison d'un trop grand nombre de tentatives de connexion infructueuses. Veuillez réessayer plus tard ou réinitialiser votre mot de passe.",
    errorUnknown: "Une erreur inattendue s'est produite",
    forgetPassword: "j'ai oublié mon mot de passe",
  },
  Physicians: {
    FormThankYou: "Nous vous remercions de votre demande. Nous vous contacterons dans les plus brefs délais.",
    FormError: "Une erreur s'est produite. Essayez à nouveau ou envoyez-nous un e-mail.",
    Title: "Vos patients sont entre de bonnes mains",
    HeaderInShort: "Notre service en bref",
    BodyDelivery: "Nous livrons régulièrement les médicaments des patients à leur domicile.",
    BodyPresort: "Les médicaments sont pré-triés en fonction du plan de traitement.",
    BodyReminder: "Notre application rappelle aux patients de prendre leurs médicaments et facilite la demande d'ordonnances de suivi.",
    HeaderWeNeed: "Ce dont nous avons besoin de votre part",
    BodyCoveredByHI: "Si votre patient(e) doit prendre au moins 3 médicaments différents par semaine, la caisse d'assurance maladie prend en charge les frais d'emballage en semainier des médicaments. Pour cela, votre patient(e) a besoin d'une ordonnance pour un système de dosage hebdomadaire. Pour cela, il suffit de mentionner sur l'ordonnance \"système de dosage hebdomadaire\".",
    HeaderKF: "Faits clés",
    Header50pc: "50%",
    Body50pc: "des personnes souffrant d'une maladie chronique ne prennent pas leurs médicaments comme prescrit.",
    TxtSourceWHO: "Source : OMS",
    Header200k: "200'000",
    Body200k: "personnes meurent chaque année des suites de la non-adhésion.",
    Header125G: "125 milliards d'euros",
    Body125G: "de coûts supplémentaires pour le système de santé sont générés chaque année par la non-adhérence en Europe.",
    TxtSourceOECD: "Source : OCDE",
    TxtAnyQuestion: "Vous avez des questions ?",
    TxtUseForm: "Contactez-nous via le formulaire de contact et nous vous répondrons dans les plus brefs délais.",
    TxtPlichtsFeld: "*Champ obligatoire",
    TxtDSV1: "En nous contactant, vous acceptez nos",
    TxtDSV2: "Politique de confidentialité",
    BtnSend: "Envoyer",

  },
  subscription: {
    signup: {
      title: "Créer un compte",
      labelFirstName: "Prénom",
      labelLastName: "Nom de famille",
      labelTitle: "Titre",
      optionMale: "Monsieur",
      optionFemale: "Madame",
      labelTelephone: "Téléphone",
      labelEmail: 'Adresse e-mail',
      labelEmailConfirm: "Répéter l'e-mail",
      labelAcceptNews: "Je souhaite être informé des nouveautés et des offres",
      labelAcceptTerms: "J'accepte les conditions d'utilisation",
      labelAcceptData: "J'accepte le traitement des données personnelles conformément à la déclaration de confidentialité",
      labelHasEmail: "J'ai un e-mail",
      labelNoEmail: "Je n'ai pas d'e-mail",
      btnCreateAccountTxt: "Créer un compte",
      labelNoEmailText: "Attention: sans adresse e-mail, vous n'avez pas accès à l'application DoryGo.",
      labelHasAccount: "Vous avez déjà un compte",
      labelHasAccountLink: "Se connecter",
      labelPassword: "Mot de passe",
      titleLogin: "Se connecter",
      btnLoginTxt: "S'enregistrer",
      labelForgetPassword: "Mot de passe oublié ?",
      btnLoginBack: "Retour",
      labelInsurance: "Assurance",
      labelIdentificationNumber: "Numéro d'identification",
      errorValueNeeded: "Veuillez remplir ce champ",
      errorEmail: "E-mail non valide",
      errorPasswordInvalid:
        "Le mot de passe doit comporter au moins 8 caractères et contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial",
      errorPasswordMissmatch: "Les mots de passe ne correspondent pas",
      errorPermissionRequired: "Consentement requis pour continuer",
      errorPhoneInvalid: "Votre numéro de téléphone n'est pas valide",
      labelPassword: "Mot de passe",
      labelPasswordRepeat: "Répéter le mot de passe",
      errorWrongPassword: "Mot de passe ou e-mail non valide",
      errorTooManyRequests:
        "L'accès à ce compte a été temporairement désactivé en raison d'un trop grand nombre de tentatives de connexion infructueuses. Veuillez réessayer plus tard ou réinitialiser votre mot de passe",
      errorUnknown: "Une erreur inattendue s'est produite",
      errorEmailExists: "Cette adresse e-mail est déjà utilisée",
      ButtonContinue: "Suivant",
      labelCreateAccount: "Créer un compte",
      Announce: "Se connecter",
      PharmacyElections: "Choisir une pharmacie",
      OrderOverview: "Aperçu de la commande",
      Managed: "Fait",
      labelBirthdate: 'Anniversaire',
      errorValidDate: "Sélectionnez une date valide.",
    },
    order: {
      title1: "Choisir le canton",
      title2: "Choisissez votre pharmacie",
      title3: "Carte d'assurance",
      labelCanton: "Canton",
      labelPharmacy: "Pharmacie",
      labelSubmit: "Vers l'aperçu de la commande",
      btnLoginBack: "Retour",
      labelDescriptionText: "Votre pharmacie a besoin des informations suivantes concernant votre assurance afin de pouvoir facturer les médicaments et le service",
      labelCardHolder: "Nom de l'assurance",
      labelCardNumber: "Numéro de carte",
      labelOrderBtn: "Finaliser la commande",
      title4: "Votre commande",
      title5: "Informations personnelles",
      title6: "Votre pharmacie",
      errorInvalidCardNumber:
        "Numéro de carte non valide. Le numéro doit comporter 20 caractères et ne doit contenir que des chiffres et des espaces",
      text1: "Livraisons ou collectes régulières",
      text2: "Rappels de prise",
      text3: "Boîte pour les déplacements",
      text4: "CHF gratuit",
      text5: "CHF 21.60 par semaine sans l'ordonnance de dosage hebdomadaire ",
      labelInsurance: "Assurance",
      labelIdentificationNumber: "Numéro d'identification",
      errorValueNeeded: "Veuillez remplir ce champ",
    },
    final: {
      title: "Merci beaucoup pour votre commande !",
      labelButton1: "Donner une procuration",
      labelButton2: "Terminé",
      title2: "Il ne manque plus que vos ordonnances de médicaments",
      title3: "Comment souhaitez-vous soumettre vos ordonnances ?",
      labelSwitch1: "En personne",
      labelSwitch2: "By mail",
      labelSwitch3: "Je souhaite déléguer cela à DoryHealth",
      surveyTitle: "Comment avez-vous entendu parler de nous ?",
      option1: "Ma pharmacie",
      option2: "Mon médecin",
      option3: "Dépliant reçu dans ma boîte aux lettres",
      option4: "En ligne, après une recherche",
      option5: "Les Invisibles/ Tamara",
      option6: "Quelqu'un m'a parlé de DoryHealth",
      question: "Qui ça?",
      error: "Le texte d'entrée doit comporter au moins 2 caractères."
    }
  }
}